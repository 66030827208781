
@import '../../../../../../styles/variables.scss';
 
.invoices {
    display: flex;
    margin: 3rem -2rem 0 -2rem;
    &Col {
        margin: 0 2rem;
        flex-basis: 50%;
        max-width: 50%;
    }
    &ListItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-1;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        a:hover {
            color: $tertiary;
        }
    }
    &ReadActions {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        margin: 1rem;
    }
}


.pickerWrapper {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 2px .5rem;
    flex-basis: 240px;
    max-width: 280px;
    display: flex;
    background: #fff;
    align-items: center;
}

.picker {
    border-radius: 10px;
    border: 1px solid #80b814;
    padding: 6px .5rem;
    margin-left: 1rem;
    &Active {
        background: #80b814;
        color: #fff;
        input {
            color: #fff;
        }
    }
    input {
        width: 140px;
        border: none;
        margin-bottom: 0;
        padding: 0;
        font-weight: bold;

    }
    ::placeholder {
        color: #80b814;
    }
}