@import '../../../../styles/variables.scss';

.invoices {
    display: flex;
    margin: 30px -10px 0 -10px;
    &Col {
        flex-basis: 33%;
        max-width: 33%;
        flex-grow: 1;
        margin: 0 10px;
        &Title {
            font-size: 18px;
            border-bottom: 2px solid $secondary;
            padding-bottom: 3px;
            margin-bottom: 20px;
            text-transform: capitalize;
        }
    }
}