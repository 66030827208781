.wtrTable {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    th, td {
        padding: 8px;
        border-bottom: 1px solid #ccc;
        text-align: center;
    }
    th {
        text-align: center;
        font-weight: bold;
        line-height: 1;
    }
    &Name {
        text-align: left!important;
        width: 350px;
        font-size: 2rem;
    }

    
}

.stat {
    border: 1px solid #CCC;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    flex: 1 1;
    padding: 20px 5px;
    margin: 0 10px;
    text-align: center;
    &Label {
        color: #999999;
        font-size: 1.4rem;
    }

    &Count {
        color: #000;
        font-size: 22px;
    }
}

//extra WTR report
.schoolReports {
    margin: 2rem 0;
    max-width: 80rem;
}
.school {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #acacac;
    &Header {
        display: flex;

        &Main {
            flex: 1;
        }
        &Side {
            flex-basis: 20rem;
            flex-shrink: 0;
            text-align: right;
        }
    }
    h1, h2, h3 {
        font-weight: normal;
    }
    &Book {
        margin-bottom: 2rem;
        &Header {
            display: flex;
            justify-content: space-between;
            margin-bottom: .5rem;
            align-items: center;
        }
        &Status {
            flex-shrink: 0;
            flex-basis: 15rem;
        }
    }
    &Stats {
        font-size: 18px;
    }
}

.csvBtn {
    background: linear-gradient(#F2F2F2, #D4D4D4);
    border: 1px solid #979797;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 34px;
    padding: 0 10px;
    color: #4a4a4a;
    margin-right: 10px;
}