svg {
    outline: none;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.wrapper {
    display: grid;
    grid-template-columns: 5fr 2fr;
    gap: 3rem;
}
.subDates {
    font-weight: bold;
    color: #3A3A3A;
    span {
        font-weight: normal;
        padding-right: 2rem;
    }
}

.panel {
    background: #FAFAFA;
    padding: 2rem;
    border-radius: 1rem;
}

.prevSub {
    display: flex;
    background: #FAFAFA;
        padding: 1rem;
        border-radius: 1rem;
    margin-bottom: 5px;
}

.invStatus {
    background: #c6cbb5;
    font-weight: bold;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 10px;
}

.invList {
    display: flex;
    justify-content: space-between;
}

.updateBilling {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.editPanel {
    padding: 20px;
    border: 1px solid #CCC;
    margin: 20px 0;
}

.updateInvoice {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
}

.invoiceLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    size: 16px;
    margin-bottom: 10px;
}