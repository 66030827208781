@import '../../styles/variables.scss';

.Header {
    display: flex;
    border-bottom: 1px solid #000;
}

.HeaderTitle {
    margin-right: 10px;
}

.TabsContainer,
.Header {
    padding: 20px 0;
}

.notificationsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}

.notificationWrapper {
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.16);
    color: $body-color;
    display: block;
    width: 30%;
    max-height: 300px;
    position: relative;
}

.emptyNotification {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    cursor: pointer;
}

.TagsContainer {
    margin-bottom: 1rem;
}

.Tag {
    padding: 5px;
    margin-right: 1rem;
    color: #fff;
}

.New {
    background-color: $danger;
}

.BoomReader {
    background-color: #1E2671;
}

.BoomWriter {
    background-color: $accent-red;
}

.ArtWorkPreview {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.MessageElipsis {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Required for multiline truncation */
}