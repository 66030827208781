@import '../../styles/variables';

.saveConfirm {
    align-items: center;
    color: darken($primary, 10%);
    display: flex;
    margin: 1rem 0;
    transition: all 0.5s;
    opacity: 0;
    &Icon {
        margin-right: .5rem;
        width: 2rem;
        height: 2rem;
    }
}

.fadeIn {
    opacity: 1;
}