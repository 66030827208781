@import '../../../../../../styles/variables.scss';

.item {
    padding: 1rem;
    border: 1px solid darken(#ccc, 40%);
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &:hover {
        background: lighten($gray-1, 10%);
    }
    &Info {
        flex: 1;
        h3 {
            font-size: 1.4rem;
        }
    }
    &Label {
        flex-shrink: 0;
        flex-grow: 0;
    }
}