@import '../../styles/variables';

.errorPage {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 2rem;
    text-align: center;
    text-transform: uppercase;

    &Title {
        font-size: 5rem;
        margin: 0;
    }

    &Text {
        font-size: 2.4rem;
    }

    &Button {
        margin-top: 2rem;
        background: $primary;
        border: 1px solid $primary;
        border-radius: 9px;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        padding: 6px 20px;
        text-transform: uppercase;

        &:hover {
            background: lighten($primary, 5%);
        }
    }
}

@media screen and (min-width: 36em) {
    .errorPage {
        &Title {
            font-size: 9rem;
        }
    }
}