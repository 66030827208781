@import '../../../../styles/variables.scss';


.invoice {
    margin-top: 2rem;
    &Summary {
        display: flex;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        margin-bottom: 3rem;
        &Col {
            flex-basis: 50%;
            max-width: 50%;
            flex-grow: 1;
            margin: 0 1.5rem;
        }
    }

    &OfferDetails {
        font-weight: bold;
        font-size: 1.8rem;
        li {
            display: flex;
            justify-content: space-between;
        }
        &Total {
            border-bottom: 2px solid  darken($gray-1, 20%);
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }
        &Pack {
            border-bottom: 1px solid darken($gray-1, 5%);
            padding-bottom: .5rem;
            margin-bottom: .5rem;
            display: block!important;
        }
        &Main {
            display: flex;
            justify-content: space-between;
        }
        &Extra {
            font-weight: normal;
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
        }

    }

    &Itemized {
        &Group {
            padding-bottom: .5rem;
            margin-bottom: 1rem;
        }
        &Row {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid darken($gray-1, 5%);
            padding-bottom: .5rem;
            margin-bottom: .5rem;
        }
    }
    &OrderLink {
        color: $tertiary;
        text-decoration: underline;
        margin-right: 1rem;
        &:hover {
            text-decoration: none;
        }
    }
}

.prevSub {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    padding-bottom: 5px
}
.prevSub:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
}


.note {
    padding-bottom: .5rem;
    margin-bottom: .5rem;
    border-bottom: 1px solid #ccc;
    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .5rem;
    }
    &Date {
        font-size: 1.2rem;
        color: $gray-3;
        flex-basis: 8rem;
        flex-shrink: 0;
        flex-grow: 0;
    }
}
