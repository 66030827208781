@import '../../../../../../../../styles/variables.scss';

.form {
    max-width: 44rem;
    &Search {
        position: relative;
        display: flex;
        align-items: center;
        display: inline-block;
        margin-right: 2rem;
        margin-left: .5rem;
        width: 30rem;
        &Btn {
            position: absolute;
            right: .5rem;
            top: .5rem;
            padding: 0;
            border: none;
            cursor: pointer;
        }
    }
}
.school {
    background: $gray-1;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
}

.klsWrapper {
    flex-wrap: wrap;
}
.kls {
    padding: 1rem;
    background: lighten($gray-1, 10%);
    margin-bottom: .5rem;
    margin-right: .5rem;
    flex-basis: calc(50% - .5rem);
    max-width: calc(50% - .5rem);
    flex-shrink: 0;

}

