.subscription {
    &ReadToggle {
        width: 10rem!important;
    }
    &ReadSwitch {
        &:checked ~ label:before {
            content: 'active'!important;
        }
    }
    &ReadLabel {
        &:before {
            content: 'inactive'!important;
            width: 10rem!important;
        }
    }
}