.section {
    margin: 0 -7px;
    max-width: 700px;
    display: flex;
    &Link,
    &Stat {
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 10px;
        flex-basis: 33%;
        max-width: 33%;
        flex-grow: 1;
        margin: 0 7px;
        text-align: center;
        cursor: pointer;
        &:hover {
            background: lighten(#ccc, 10%);
        }
    }
    &Title {
        font-weight: bold;
        color: darken(#ccc, 20%);
        margin-bottom: 5px;
    }
    &TitleLarge {
        font-weight: bold;
        font-size: 24px;
    }
    &Count {
        font-weight: bold;
        font-size: 24px;
    }
}

.pageHeaderIndia {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &Title {
        padding-left: 5rem;
        background: url('../../images/logos/india.png') no-repeat left center;
    }
}

.LinkContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

  &Icon {
    margin-top: 30px;
  }
}