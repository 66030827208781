.listItem {
    align-items: center;
    border-bottom: 1px #979797 solid;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    justify-content: space-between;
    padding: 20px 0;
    &Left {
        &Main {
            align-items: center;
            display: flex;
        }
    }
    &MainText,
    &RightButton {
        cursor: pointer;
        &:hover {
            color: #979797;
        }
    }
    &SubText {
        font-size: 1.6rem;
    }
    &RightButton {
        margin-left: 10px;
    }
}
.short {
    padding: 10px 0;
}