@import '../../../../styles/variables.scss';

.orders {

    &Search {
        margin: 2rem 0 2rem 0;
        position: relative;
        display: flex;
        align-items: center;
        display: inline-block;
        margin-right: 2rem;
        margin-left: .5rem;
        width: 27rem;
        &Btn {
            position: absolute;
            right: .5rem;
            top: .5rem;
            padding: 0;
            border: none;
            cursor: pointer;
        }
        &Code {
            width: 15rem;
        }
    }
    

    &List {
        display: flex;
        margin-bottom: 2rem;
        &Nav {
            flex-basis: 12rem;
            flex-shrink: 0;
            margin-right: 3rem;
            li {
                display: block;
                margin-bottom: .5rem;
                padding: .3rem;
                background: #333;
                color: #fff;
                border-radius: .5rem;
                text-align: center;
                font-weight: bold;
                cursor: pointer;
            }
            &Active {
                background: #0097AF!important;
            }
        }
        &Main {
            flex: 1;
        }
    }
    &LoadMore {
        margin-top: 2rem;
        border: none;
        border-radius: 1rem;
        background: lighten($gray-1, 10%);
        display: block;
        cursor: pointer;
        width: 100%;
        &:hover {
            background: lighten($gray-1, 5%);

        }
    }

}