.class {
    border: 1px solid #979797;
    border-radius: 10px;
    margin: 10px 0;
    &Header {
        border-bottom: 1px solid #979797;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        &Left {
            display: flex;
            font-size: 2rem;
        }
    }
    &EditButton {
        cursor: pointer;
        &:hover {
            color: #979797;
        }
    }
    &Name {
        font-weight: bold;
        margin-right: 10px;
    }
    &Content {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        &Teachers,
        &Packs {
            flex: 1;
        }
        &Packs {
            align-items: flex-end;
            display: flex;
            flex-direction: column; 
            text-align: right;
        }
    }
    &Button {
        align-items: center;
        border: none;
        cursor: pointer;
        display: flex;
        margin-top: 20px;
        padding: 0;
        &Icon {
            margin-right: 5px;
        }
        &:hover {
            color: #979797;
        }
    }
    &Teacher,
    &Pack {
        align-items: center;
        display: flex;
        margin-bottom: 5px;
        &Icon {
            height: 20px;
            margin-right: 5px;
            width: 20px;
        }
    }
    &Pack {
        &Icon {
            margin-right: 0px;
            margin-left: 5px;
        }
    }
    
    &AddTeacherButton,
    &AssignPackButton {
        align-items: center;
        border: none;
        border-bottom: 1px solid #979797;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        width: 100%;
        &Left {
            display: flex;
        }
        &Students {
            align-items: center;
            display: flex;
        }
        &Icon {
            height: 20px;
            margin-right: 5px;
            width: 20px;
        }
    }
}