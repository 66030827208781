@import '../../styles/variables.scss';

.formInput {
    max-width: 200px;
}

.listing {
    max-width: 60rem;
}
.link {
    font-weight: bold;
    color: darken($primary, 10%);
}
.childrenList {
    list-style: none;
    li {
        padding-bottom: .3rem;
        a {
            color: darken($primary, 10%);
        }
    }
}