.button {
    background: linear-gradient(#F2F2F2, #D4D4D4);
    border: 1px solid #979797;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 34px;
    padding: 0 10px;
    &:hover {
        background: #F2F2F2;
    }
    &Primary {
        background: linear-gradient(#d8d8d8, #b3b3b3);
        font-weight: bold;
        &:hover {
            background: #d8d8d8;
        }
    }
    &:disabled {
        background: #F2F2F2;
        color: #979797;
        pointer-events: none;
    }
    &FullWidth {
        width: 100%;
    }
    &Large {
        font-size: 28rem;
        line-height: 48rem;
        padding: 0 20px;
    }
}