a[disabled] {
    pointer-events: none;
}

a {
    text-decoration: none;
    color: inherit;
}
.link {
    cursor: pointer;
}

.link-reverse {
    color: $body-color;
    &:hover {
        color: $primary;
    }
}
.link-underline {
    text-decoration: underline;
}

.btn-reset {
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: left;
    &:focus {
        outline: none;
    }
}
.btn-small {
    padding: .4rem 1rem;
    font-size: 1.4rem;
}