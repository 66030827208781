


@each $prop, $abbr in $space-properties {
  @each $size, $len in $spacers {
    .u-#{$abbr}-top-#{$size} {
      #{$prop}-top: $len !important;
    }
    .u-#{$abbr}-base-#{$size} {
      #{$prop}-bottom: $len !important;
    }
    .u-#{$abbr}-left-#{$size} {
        #{$prop}-left: $len !important;
    }
    .u-#{$abbr}-right-#{$size} {
        #{$prop}-right: $len !important;
    }
    .u-#{$abbr}-#{$size} {
        #{$prop}: $len !important;
    }
  }
}

.u-m-left-auto {
    margin-left: auto;
}
.u-m-right-auto {
    margin-left: auto;
}


// text helpers
// color
.u-t-white {
	color: #fff;
}

// alignment
.u-text-center {
  text-align: center;
}
.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

// display
.u-display-flex {
    display: flex;
}
.u-justify-between {
    justify-content: space-between;
}
.u-align-center {
    align-items: center;
}

.u-d-inline-block {
  display: inline-block!important;
}
 
.separator {
  height: 1px;
  background: #ccc;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: none;
  border: none;
}