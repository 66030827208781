@import '../../styles/variables.scss';

.invoicesTypes {
    display: flex;
    margin-top: 2rem;
    &Item {
        background: #FAFAFA;
        padding: 2rem 3rem;
        margin-right: 1.6rem;
        border-radius: 1rem;
        font-weight: bold;
        font-size: 1.8rem;
        display: block;
        &:hover {
            color: $primary
        }
    }
}

.container {
    display: flex;
    margin-top: 3rem;
    align-items: flex-start;
    &Side {
        flex-basis: 20rem;
        flex-shrink: 0;
        margin-right: 3rem;
        background: #FAFAFA;
        border-radius: 1rem;
        padding: 1rem 0;
    }
    &Main {
        flex: 1;
    }
}

.invoiceTypes {
    &Btn {
        padding: .4rem 1.6rem;
        display: block;
        width: 100%;
        text-align: left;
        border: none;
        cursor: pointer;
        &Active {
            background: #D8D8D8;
            font-weight: bold;
        }
    }
}
.invoiceActions {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
    justify-content: space-between;
    &Search {
        flex-basis: 40%;
        max-width: 40%;
        position: relative;
        &Field {
                border-radius: 1rem;
                border: 1px solid $gray-2;
                padding: .5rem 1rem;
                width: 100%;
            }
        &Btn {
            position: absolute;
            right: 1rem;
            top: .5rem;
        }

    }
    &Sort {
        flex-basis: calc(60% - 3rem);
        max-width: calc(60% - 3rem);
        display: grid;
            grid-template-columns: 1fr 1fr;
        align-items: center;
    }
  
    

}
.invoiceTable {
    table-layout: fixed;
    border-collapse: collapse;
    th {
        text-align: left;
        font-weight: bold;
        padding: 5px;
        border-bottom: 1px solid #858585;
    }
    td {
        padding: 5px;
        border-bottom: 1px solid #858585;
    }
    tr {
        cursor: pointer;
    }
    tr:nth-of-type(odd) td {
        background: #F5F5F5;
    }
    &No {
        width: 10rem;
    }
    &Main {
        width: 38rem;
    }
    &Title {
        font-size: 1.8rem;
    }
}
.markTerm {
    background: none;
    color: #117297;
    font-weight: bold;
    font-style: italic;
}
.invoiceLabel {
    color: #fff;
    display: inline-block;
    border-radius: 1rem;
    background: #FF0000;
    padding: 2px 10px;
}