.sectionHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}

.form {
    &Search {
        position: relative;
        display: flex;
        align-items: center;
        display: inline-block;
        margin-right: 2rem;
        margin-left: .5rem;
        width: 45rem;

        &Btn {
            position: absolute;
            right: .5rem;
            top: .5rem;
            padding: 0;
            border: none;
            cursor: pointer;
        }
    }
}