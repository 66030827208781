.header {
    background: #f2f2f2;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    position: fixed;
    width: 100%;
    z-index: 10;
}
.headerInner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1100px;
    padding: 8px 20px;
    &Left {
        display: flex;
        align-items: center;
    }
}
.headerHomeLink {
    align-items: center;
    display: flex;
    margin-right: 2rem;
    &:hover {    
        color: #888888;
    }
}
.headerLogo {
    height: 40px;
    margin-right: 5px;
    width: 40px;
}
.headerTitle {
    font-size: 18px;
}
.headerLogout {
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 3rem;
    &:hover {    
        color: #888888;
    }
}

.headerLinks {
    display: flex;
    align-items: center;
    a {
        margin-right: 2rem;
    }
}