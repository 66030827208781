.backButton {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    margin: 20px 0 10px;
    padding: 0;
    &Icon {
        margin-right: 5px;
    }
    &:hover {
        color: #979797;
    }
}