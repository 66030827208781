@import '../../../../styles/variables.scss';


.order {
    &StatusPanel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        padding: 1rem;
        background: lighten($gray-1, 10%);
        font-weight: bold;
        svg {
            margin-right: 1rem;
        }
        &Main {
            display: flex;
            align-items: center;
        }
        &New {
            background: $accent-red;
            color: #fff;
        }
        &Done {
            background: none;
            padding: 1rem 0;
            border-bottom: 1px solid $gray-1;
        }
    }

    &Main {
        display: flex;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        margin-bottom: 3rem;
        &Col {
            flex-basis: 50%;
            max-width: 50%;
            flex-grow: 1;
            margin: 0 1.5rem;
        }
    }

    &Itemized {
        li {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid darken($gray-1, 5%);
            padding-bottom: .5rem;
            margin-bottom: .5rem;
        }
    }
    &Timeline {
        margin-top: 2rem;
        li {
            display: flex;
            align-items: center;
            font-weight: bold;
            margin-bottom: 1rem;
            font-size: 1.8rem;
            div {
                flex-basis: 50%;
                max-width: 50%;
                &:first-of-type {
                    text-align: right;
                }
                &:last-of-type {
                    display: flex;
                    align-items: center;
                    margin-left: 1rem;
                }
            }
        }
    }
}