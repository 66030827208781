@import '../../../../styles/variables.scss';

.container {
    overflow: hidden;
}
.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-1;
    &Icon {
        cursor: pointer;
        &:hover {
            color: #979797;
        }
    }
}
.smallStats {
    display: flex;
    margin: 0 -7px 20px;
}
.smallStat {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    flex-basis: 33%;
    max-width: 50%;
    flex-grow: 1;
    margin: 0 7px;
    text-align: center;
    &Title {
        font-weight: bold;
        color: darken(#ccc, 20%);
        margin-bottom: 5px;
        button {
            color: $primary;
        }
    }
    &Count {
        font-weight: bold;
        font-size: 24px;
    }
    &Perc {
        font-weight: bold;
        font-size: 18px;
    }
}
.sectionTitle {
    font-size: 21px;
    font-weight: bold;
    color: darken(#ccc, 20%);
    margin: 5px 0;
}

.largeStats {
    display: flex;
    margin: 0 -20px;
}
.largeStat {
    border: 1px solid #979797;
    border-radius: 12px;
    cursor: pointer;
    flex: 1;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 20px;
    padding: 10px;
    text-align: center;
    &:hover {
        background: #f2f2f2;
    }
    &Title {
        color: #9c9c9c;
    }
    &Count {
        font-size: 5rem;
    }
}
.subViewTopBar {
    display: flex;
    justify-content: space-between;
}

.panel {
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    background: #fafafa;
}

.invoiceTable {
    table-layout: fixed;
    border-collapse: collapse;
    th {
        text-align: left;
        font-weight: bold;
        padding: 5px;
        border-bottom: 1px solid #858585;
    }
    td {
        padding: 5px;
        border-bottom: 1px solid #858585;

        &.issuedAt {
            width: 10rem;

            .issuedAtData {
                display: flex;
                align-items: center;
            }
        }
        &.amount {
            width: 38rem;
        }
        &.linkToInvoice {
            text-align: right;

            .link {
                cursor: pointer;
            }
        }
    }
    tr:nth-of-type(odd) td {
        background: #f5f5f5;
    }
}
