// Breakpoints
$mobile-landscape: 480px;	// Mobile-landscape (and larger)
$tablet-portrait: 768px;	// Tablet-portrait (and larger)
$tablet-landscape: 992px;	// Tablet-landscape (and larger)
$laptop: 1200px;	// Laptops (and langer)


//Spacing
$spacer: 10px !default;
$spacers: (
  0: $spacer * 0,
  1: $spacer * 0.5,
  2: $spacer * 1,
  3: $spacer * 2,
  4: $spacer * 3
)!default;

$space-properties: (
  margin: m,
  padding: p
);


//typography
$base-line-height:            1.6 !default;

$base-font-size:              1.4rem !default;
$lead-font-size:              1.6rem !default;

$base-desktop-font-size:      1.6rem !default;
$lead-desktop-font-size:      1.8rem !default;

$small-font-size:             1.2rem !default;

$body-font-family:            "Muli", sans-serif !default;
$headings-margin-bottom:      $spacer !default;
$headings-font-family:        "Muli", sans-serif !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$h1-font-size:                $base-font-size * 1.8 !default;
$h2-font-size:                $base-font-size * 1.6 !default;
$h3-font-size:                $base-font-size * 1.4 !default;

$h1-desktop-font-size:        $base-desktop-font-size * 1.875 !default;
$h2-desktop-font-size:        $base-desktop-font-size * 1.5 !default;
$h3-desktop-font-size:        $base-desktop-font-size * 1.125 !default;

//Colors
$body-color   : #3A3A3A;
$primary-light: #C2D582;
$primary      : #9DC030;
$primary-dark : #5B7223;
$secondary    : #FFD653;
$tertiary     : #0097AF;
$accent-red   : #C33764;

$danger       : red;
$warning      : darken($secondary, 5%);


$gray-1:     #D9D9D9 !default;
$gray-2:     #C6CBB5 !default;
$gray-3:     #4A4A4A !default;

$theme-colors: (
  "dark"          : $body-color,
  "white"         : #fff,
  "primary"       : $primary,
  "primary-dark"  : $primary-dark,
  "tertiary"      : $tertiary,
  "danger"        : $danger,
  "muted"         : darken($gray-2, 10%)
);
