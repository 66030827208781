html { 
   // font-size: 1px;/*for using REM units*/
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16rem;
    font-weight: 400;
    line-height: 1.3;
    color: #4A4A4A;
}

a {
    color: inherit;
    text-decoration: none;
}

.light {
    font-weight: normal;
}
.body-text {
    font-size: 16rem;
}
.small {
    font-size: 12rem;
}