%card-base {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 1rem;
    flex: 1 0 0;
  }
  .card {
      @extend %card-base;
      box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
      &.card-hover {
          color: $body-color;
          transition: all .2s ease-in;
          &:hover {
            border-color: rgba(0, 0, 0, 0.2);
            box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.2);
          }
      }
      .card-header {
          padding: 2rem;
      }
      .card-body {
          flex: 1 1 auto;
          padding: 2rem;
      }
  }

  
  .card-muted {
      background: lighten($gray-1, 5%);
      padding: 2rem;
  }
  