@import '../../styles//variables.scss';
@import '../Notifications/Notifications.module.scss';

.Header {
    display: flex;
    border-bottom: 1px solid #000;
    justify-content: space-between;
    align-items: center;
}

.HeaderTitle {
    margin-right: 10px;
}

.TitleIconContainer {
    display: flex;
}

.Header,
.NotificationContainer {
    padding: 20px 0;
}

.fileUpload {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.NotificationContainer {
    display: flex;
    justify-content: space-between;
}

.NotificationForm {
    width: 55%;

    .notificationWrapper {
        width: 100%;
    }
}

.NotificationPreview {
    width: 40%;

    .notificationWrapper {
        width: 100%;
        position: relative;
        min-height: 280px;
    }
}

.ToggleLabelGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ToggleFormWrapper {
    display: flex;
    align-items: center;
    width: 40%;
    margin-top: 2rem;
}

.SwitchLabel {
    font-size: 16px;
    margin-left: 1rem;
}

.PublishButton,
.ArchiveButton,
.EditButton {
    padding: 6px 20px;
    margin-left: 26px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}

.PublishButton {
    background-color: $primary;
}

.ArchiveButton {
    background-color: #00c8ff;
}

.EditButton {
    background-color: $accent-red;
}

.HyperlinkWrapper {
    width: 100% !important;
}

.ArtWorkUpload {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.ArtWorkPreview {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
