.title {
    font-size: 3rem;
    font-weight: bold;
}
.subtitle {
    color: #787878;
    font-size: 2.4rem;
    font-weight: bold;
    &--underlined {
        border-bottom: 1px solid #787878;
        padding-bottom: 10px; 
    }
}
.bigger-text {
    font-size: 1.8rem;
    line-height: 24px;
}
.body-text {
    font-size: 1.6rem;
}
.smaller-text {
    font-size: 1.4rem;
}
.u-bold {
    font-weight: bold;
}