.sectionHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}
.section {
    margin: 0 -7px;
    max-width: 700px;
    display: flex;
    &Link,
    &Stat {
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 10px;
        flex-basis: 33%;
        max-width: 33%;
        flex-grow: 1;
        margin: 0 7px;
        text-align: center;
        cursor: pointer;
        &:hover {
            background: lighten(#ccc, 10%);
        }
    }
    &Title {
        font-weight: bold;
        color: darken(#ccc, 20%);
        margin-bottom: 5px;
    }
    &TitleLarge {
        font-weight: bold;
        font-size: 24px;
    }
    &Count {
        font-weight: bold;
        font-size: 24px;
    }
}

.tableRow {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.tHeading {
    font-weight: bold;
    font-size: 18px;
}

.tableMain {
    flex-grow: 1;
}
.tableTeacher {
    font-weight: bold;
}
.tableDate {
    margin-right: 40px;
    display: flex;
    align-items: center;
}