@import '../../../../../../styles/variables.scss';

.item {
    padding: .5rem 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &Link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &:hover {
        background: lighten($gray-1, 10%);
    }
    &Info {
        font-size: 1.6rem;
        display: flex;
    }
    &Ref {
        min-width: 7.5rem;
    }
    &Meta {
        min-width: 11rem;
        margin-right: 2rem;
    }
    &Label {
        width: 8rem;
        padding-left: 0!important;
        padding-right: 0!important;
    }
    &Side {
        display: flex;
        align-items: center;
    }
}