
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    min-height: 100%;
}

body {
    font-family: $body-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: left;
    color: $body-color;

}

.login {
    margin: auto;
    max-width: 50rem;
}