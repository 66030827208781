@import '../../../../../../../../styles/variables.scss';

.resultsWrapper {
    &List {
        position: absolute;
        top: 170px;
        left: 25px;
        right: 25px;
        background: #fff;
        max-height: 200px;
        overflow-y: auto;
        padding: 1.2rem;
        border-radius: 1rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.2);
        border: 1px solid $gray-2;
        li {
            padding: .5rem 0;
            h4 {
                cursor: pointer;
                &:hover {
                    color: $primary
                }
            }
        }
    }
}