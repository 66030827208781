@import '../../styles/variables.scss';

.beeRounds {
    li {
        margin-bottom: 2rem;
    }
    &Stats {
        display: flex;
        
    }
}
.classes {
    margin: 2rem 0;
    &Item {
        border-radius: 1rem;
        background: #FAFAFA;
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .8rem;
        font-size: 2rem;
        cursor: pointer;
        &Main,
        &Side {
            display: flex;
            align-items: center;
            font-weight: bold;
            svg {
                margin: .5rem;
            }
        }
        &Active {
            color: #fff;
            background: $primary;
        }
    }
}
.stMaxInput {
    padding: 5px;
    border-radius: 1rem;
    border-color: $gray-1;
    max-width: 7rem;
    text-align: center;
}

.statsBar {
    display: flex;
    align-items: center;
}

.statsBarSearch {
    margin-right: auto;
}

.statsBarTabs {
    display: flex;
    gap: 10px;
}

.statsBarTab {
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
    border: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
    span {
        font-size: 20px;
    }
    &Active {
        background: #696868;
        color: #FFF;
    }
}

.searchStatus {display: flex;justify-content: space-between;background: #fafafa;
margin: 40px 0 0 0;padding: 5px;border-radius: 5px;
}

.statsBarSearch {
    display: flex;
    gap: 10px;
}

.searchButton {
    padding: 5px 10px;
    background: #80b814;
    color: #FFF;
    font-weight: bold;
    border: none;
    border-radius: 5px; 
}

.searchBar {
    border-radius: 5px;
    padding: 5px;
}
.backLink {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    margin: 20px 0 10px;
    padding: 0;
    &Icon {
        margin-right: 5px;
    }
    &:hover {
        color: #979797;
    }
}