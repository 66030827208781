.bookSidebar {
    width: 200px;
    &Option {
        align-items: center;
        display: flex;
        font-size: 2rem;
        font-weight: bold;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    &Name {
        cursor: pointer;
        &:hover {
            color: #979797;
        }
    }
}
.selected {
    .bookSidebarName {
        color: #2BBEFA !important;
    }
}
.disabled {
    color: #d4d4d4;
    pointer-events: none;
}
.add {
    cursor: pointer;
    &:hover {
        color: #979797;
    }
    justify-content: flex-start;
}
.deleteButton {
    cursor: pointer;
    &:hover {
        color: #979797;
    }
}