//label
%label {
    display: inline-block;
    vertical-align: middle;
    border-radius: 5px;
    padding: 3px 12px;
    min-width: 40px;
    text-align: center;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
}


.label {
    @extend %label;
    &.label-primary {
        background: $primary;
        color: #fff;
    }
    &.label-muted {
        background: $gray-2;
        color: #fff;
    }
    &.label-accent-red {
        background: $accent-red;
        color: #fff;
    }
    &.label-top {
        vertical-align: top;
    }
    &.label-bee {
        display: inline-flex;
        background: #6B9A11;
        align-items: center;
        font-size: 1.2rem;
        vertical-align: unset;
        padding: 3px 6px;
        color: #fff;
        svg {
            margin-right: 4px;
        }
    }
}
