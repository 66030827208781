.header {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    justify-content: space-between;
}

.userList {
    max-width: 50rem;

    &Item {
        padding: 1rem 0;
        border-bottom: 1px solid #ccc;
        align-items: center;
        display: flex;

        &Main {
            margin-right: auto;
            max-width: 50rem;
            flex-basis: 50rem;
        }

    }
}