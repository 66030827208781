


// breakpoint functions - used wherever we need to generate sass responsive classes
// mobile first
$grid-breakpoints: (
  xs: 0,
  sm: 36em,
  md: 48em,
  lg: 62em,
  xl: 75em
);

@function get-min($pt, $pts: $grid-breakpoints) {
  $min: map-get($pts, $pt);
  @return if($min !=0, $min, null);
}
@function get-infix($name, $pts: $grid-breakpoints) {
  @return if(get-min($name, $pts) == null, "", "-"+$name);
}
@function get-next($name, $pts: $grid-breakpoints, $pts-names: map-keys($grid-breakpoints)) {
  $n: index($pts-names, $name);
  @return if($n < length($pts-names), nth($pts-names, $n+1), null);
}
@function get-max($name, $pts: $grid-breakpoints) {
  $max: get-next($name, $pts);
  @return if($max, get-min($max, $pts), null);
}

@mixin breakpoint-up($name, $pts: $grid-breakpoints) {
  $min: get-min($name, $pts);
  @if $min {
    @media screen and (min-width: $min) {@content};
  }
  @else { @content }
};

@mixin breakpoint-down($name, $pts: $grid-breakpoints) {
  $max: get-max($name, $pts);
  @if $max {
    @media screen and (max-width: $max) {@content};
  }
  @else { @content }
};
