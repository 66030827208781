.counts, .labelSet {
    display: flex;
}
.labelMid {
    align-items: center;
}
.count {
    border: 1px solid #CCC;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    flex: 1;
    padding: 20px;
    margin: 0 10px;
    text-align: center;
}

.countNum {
    color: #000;
    font-size: 22px;
}

.searchBar {
    border: 1px solid #CCC;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 25px 5px 5px; 
    width: 200px;
}

.searchBtn {
    align-items: center;
    background: none;
    appearance: none;
    border: 1px solid #CCC;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 0 10px;
}

.bookRow {
    align-items: center;
    display: flex;
    border-bottom: 1px solid #CCC;
    padding: 5px;
}

.bookRowHead {
    background: #CCC;
    border-bottom: 0;
    font-weight: bold;
    padding: 2px 5px;
}

.bookRowCode {
    width: 80px;
}

.bookRowMeta {
    line-height: 18px;
    width: calc(100% - 200px)
}

.bookRowClass {
    padding: 0 5px;
    font-weight: bold;
    font-size: 16px;
    width: 70px;
    text-align: center;
}